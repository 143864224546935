import { isProductionEnv } from '../../../config';

export type TFeatureFlagName = keyof typeof FEATURE_FLAGS_VALUE;
export const FEATURE_FLAGS_VALUE = {
  loginWithMetaMask: false,
  territoryPage: true,
  homePage: true,
  walletPage: true,
  articlePage: true,
  isArticlePubliclyAccessible: true,
  articleEditor: true,
  seoAllPagesNoIndexNoFollow: !isProductionEnv,
  commentsSection: true,
  lensIntegration: true,
  profileSettings: true,
  commentsThread: true,
  arweaveIntegration: true,
  lensComments: true,
  prompts: true,
  lensCampaign: true,
  onboardingModal: true,
  promptHeaderDropdown: true,
  inAppNotifications: true,
  territoryLeaderboard: true,
  emailNotifications: true,
  openSignUps: true,
  userGeneratedTerritories: true,
  recommendedPosts: true,
  livePrompts: true,
  search: true,
  replyToQuote: !isProductionEnv,
  territoryPageV2: !isProductionEnv,
  createNewTerritoryAsV2: !isProductionEnv,
};
